// /* DEFAULT CARD LAYOUT	*/
.card {
  border: $card-border;
  border-radius: $card-border-radius;
  box-shadow: $card-shadow;
  margin-bottom: $card-margin-bottom;

  //transition: $card-transition;

  &.slick-slide {
    margin-top: 10px;
  }

  .card-title,
  .card-title-et,
  .card-title-field {
    font-family: $card-title-font-family;
    margin-bottom: 15px;
  }

  h3 {
    display: block;
    font: $card-title-font;

    .card-title-field,
    .card-title-et,
    .card-title {
      display: block;
      font: $card-title-font;
    }
  }

  .card-text {
    padding: $card-text-padding;

    a {
      word-wrap: break-word;
    }
  }

  .card-doi {
    float: right;
  }
}

// DATES 

.card-display-date {
  //background: $card-date-bg-color;
  border-bottom: $card-date-border;
  border-radius: $card-date-border-radius;
  color: $card-date-text-color;
  font: $card-date-font;
  padding: 0 0 12px; //12px 20px 10px;
}

.node-event a.card.card-imageless {
  margin-top: 42px;
}

a.card.card-imageless {
  overflow: visible;
  margin-top: 24px;
}

.image-date-wrapper {
  position: relative;
  width: 100%;

  .card-date,
  .card-date-display-override {
    padding: 5px 12px 0;
    left: 20px;
    position: absolute;
    bottom: -5px;
    display: inline-block;
  }

  .event-date-wrapper {
    left: 20px;
    position: absolute;
    bottom: -5px;
    color: $red_radish;
    padding: 5px 12px 0;
    background: white;
    display: inline-block;

    .date-d {
      font-size: 2.333em;
      display: inline-block;
      line-height: 1;
    }

    .date-m-y {
      font-size: 1.222em;
      line-height: 1;
      display: inline-block;

      .date-m,
      .date-y {
        display: inline;
      }
    }
  }
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
{
  .card-more-link {
    display: table !important;
    margin-left: 2.35765%;
    text-align: center;

    @media (min-width: 0) and (max-width: 540px) {
      margin-left: 0;
      width: 100%;
    }

    a.card {
      box-sizing: border-box;
      display: table-cell;
      font: $card-more-link-font;
      padding: $vert-space $horz-space;
      vertical-align: middle;
      width: 100%;

      @media (min-width: 0) and (max-width: 719px) {
        display: block;
      }

      &:after {
        content: " ";
        display: inline-block;
        height: 17px;
        margin: 0 0 0 10px;
        width: 15px;
      }
    }
  }

  &.row-max-1 {
    .card-more-link,
    .card-last-link-url {
      margin-left: 0;
      float: right;
    }
  }
}

.side-row .card-more-link a.card {
  padding: $vert-space $horz-space;
  text-align: center;
  font: $card-more-link-font;

  &:after {
    content: " ";
    display: inline-block;
    height: 20px;
    margin: 0 0 -4px 10px;
    width: 20px;
  }
}

// /* FEED LINK */
.card-feed-link {
  clear: both;
  display: block;
  float: right;
  font: $card-feed-link-font;
  overflow: hidden;
  padding-bottom: 5px;

  a {
    &:after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 17px;
      //border-radius: 50%;
      margin-left: 10px;
      margin-bottom: 4px;
      vertical-align: sub;
      padding-bottom: 5px;
    }
  }
}


// /* INNER CARD ELEMENTS */
.tweet .card-text {
  display: block;
  font: normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
  background: #FFFFFF;
}

.card-video {
  margin-bottom: 15px;

  span.ext {
    display: none;
  }
}

.card-tags {
  display: inline-block;
  font-size: 0.875em;
  font-weight: 600;

  ul {
    display: inline-block;

    li {
      float: left;
      margin-right: 10px;

      &:after {
        content: "|";
        display: inline-block;
        float: right;
        margin-left: 10px;
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }
}

.card-start-date {
  margin-right: 2px;
  padding: $vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
  text-align: center;

  .card-date-day {
    font-size: 1.75em;
    line-height: 0.78571428571429;
  }

  .card-date-month {
    font-size: 0.875em;
    line-height: 1.22222222222222;
    text-transform: uppercase;
  }
}

.card-citation {
  font-style: italic;
}

.card-tweet-message {
  margin-bottom: 10px;
}

.card-date,
.card-location {
  font-size: 0.875em;
  line-height: 1.57142857142857;
}

.card-type {
  font-size: 0.875rem;
  line-height: 1.57142857142857;
}

.card-label {
  font-weight: bold;
}

.card-start-date {
  margin-right: 2px;
  padding: $vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
  text-align: center;

  .card-date-day {
    font-size: 1.75em;
    line-height: 0.78571428571429;
  }

  .card-date-month {
    font-size: 0.875em;
    line-height: 1.22222222222222;
    text-transform: uppercase;
  }
}

.card-footer .card-abbr-authors {
  margin-bottom: 20px;
  font-size: 0.777em;
}

.card-date-display-override {
  font-size: 0.777em;
}

.card-published-by {
  font-size: 0.777em;
  margin-bottom: 20px;
}

.card {
  .card-resource-type,
  .card-content-type,
  .card-gallery-term {
    display: inline-block;
    font-weight: 400;
    font-size: 0.777em;
  }

  .card-resource-type {
    padding: 4px 8px;
    border: solid 1px $red_radish;
    color: $red_radish;
  }

  .card-gallery-term,
  .card-lightbox-term {
    padding: 4px 8px;
    border: solid 1px $red_radish;
    color: $red_radish !important;
    display: inline-block;
    margin: 0 5px 5px 0;
  }
}

.card-email {
  font-weight: 600;
}

.card-job-title {
  font-size: 1em;
  line-height: 1.375;
}

.card-footer {
  display: block;
  line-height: 1.125em;
}

@media (min-width: $narrow) and (max-width: 959px) {
  .card-with-summary {
    .card-summary {
      font-size: 0.875rem;
      line-height: 1.42857142857143;
    }
  }
}

//Display box-link card-summary on hover
//@include card-hidden-summary;

.side-row {
  .node-event a.card.card-imageless {
    margin-top: 62px;
  }

  .node-news a.card.card-imageless {
    margin-top: 44px;
  }
}

// CARD LAYOUTS

.row-max-1,
.row-max-2 {
  .card-with-image {
    z-index: 0;
    position: relative;

    .image-date-wrapper {
      position: relative;
      z-index: 3;

      .card-date,
      .card-display-date-override,
      .event-date-wrapper {
        margin-left: 20px;
        left: auto;
        // bottom:auto;
        // top:20px;
        bottom: 0;
        top: auto;
      }
    }

    .card-text {
      position: relative;
      z-index: 2;
    }
  }

  .node-event .card-with-image {
    // .card-text {
    // 	margin-top:58px;
    // }
  }
}

// POSTSCRIPT
.content-main,
.content-additional,
.postscript-first {
  .row-max-1 {
    @media(min-width: 540px) {
      .card-with-image {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        .card-image {
          width: 42% !important;
          text-align: left;

          @media(min-width: 960px) {
            width: 65% !important;
          }
        }

        .card-text {
          margin: 22px 0 0 -10%;
          display: inline-block;
          height: 100% !important;
          width: 68%;
          z-index: 4;

          @media(min-width: 960px) {
            width: 68%;
            margin: 22px 0 0 -33%;
          }
        }
      }
    }
  }

  .row-max-2 {
    @media(min-width: 960px) {
      .card-with-image {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        .card-image {
          width: 67% !important;
        }

        .card-text {
          margin: 22px 0 0 -27%;
          width: 60%;
          z-index: 4;
        }
      }
    }
  }

  .row-max-1 {
    @media(min-width: 540px) {
      .node-event {
        .image-date-wrapper {
          width: 100% !important;

          .card-image {
            text-align: left;
            width: 42% !important;

            @media(min-width: 960px) {
              width: 65% !important;
            }
          }

          .event-date-wrapper {
            left: 32%;
            bottom: auto;
            top: 22px;
            width: auto;
          }
        }

        .card-text {
          margin: 68px 0 0 -68%;
        }
      }
    }
  }

  .row-max-2 {
    @media(min-width: 960px) {
      .node-event {
        .image-date-wrapper {
          .event-date-wrapper {
            left: 40%;
            bottom: auto;
            top: 22px;
            width: auto;
          }
        }

        .card-text {
          margin: 70px 0 0 -60%;
        }
      }
    }
  }
}

.search-results .search-result.card {
  background: white;
}

@media(max-width: 540px) {
  .card {
    margin-bottom: 40px;
  }
}

.view-news-events.view-display-id-events_att {
  .views-row-2 {
    margin-top: 60px;
    @media(min-width: 540px) {
      margin-top: 0;
    }
  }
}