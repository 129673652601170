@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i&display=swap');

$base-font-family      		: 'Source Sans Pro', 'Helvetica', sans-serif;
$title-font-family    		: 'Source Sans Pro', 'Georgia', serif;
$subtitle-font-family 	 	: 'Source Sans Pro', 'Georgia',serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: 600 $base-font-size/1 $base-font-family;

/* FORMS */
$button-font 							: 600 $base-font-size/1 $title-font-family;