// /* MAIN MENU */ //

$nav-base-font        	 	: 1.222rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: $sea_blue;
$nav-base-bg-color        		: $sea_blue; 
$nav-base-text-color     		 	: white;
$nav-base-padding        			: 1rem 1.5625rem;
$nav-base-hover-padding   		: 1rem 1.5625rem;

$nav-sub-base-padding					: 0.625rem 1.75rem 0.625rem 0.444rem; //0.625rem 0.875rem 0.444rem 0.444rem;
$nav-sub-base-hover-padding		: 0.625rem 1.75rem 0.625rem 0.444rem; //0.625rem 0.875rem 0.444rem 0.444rem;

$nav-base-padding-smaller 				: 0.444rem 1.125rem 0.278rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.444rem 1.125rem 0.278rem;

$nav-base-border-color        : transparent;

$nav-active-bg-color      		: white;
$nav-active-text-color    		: $red_radish;
$nav-active-border-radius 		: 0; 
$nav-active-border-color	  	: $red_radish;

$nav-hover-bg-color       		: $star_command_blue;
$nav-hover-text-color     		: $vivid_cerulean;
$nav-hover-border-color	  	  : $vivid_cerulean;

$nav-highlight-bg-color   		: $star_command_blue;
$nav-highlight-text-color 		: white; 
$nav-highlight-border-color 	: white;

$nav-sub-bg-color         		: white;
$nav-sub-text-color       		: $secondary;
$nav-sub-border-color         : transparent;

$nav-sub-hover-bg-color   		: $mint-cream;
$nav-sub-hover-text-color   	: $vivid_cerulean;
$nav-sub-hover-border-color   : $vivid_cerulean;

$nav-sub-active-border-color  : $red_radish;
$nav-sub-active-text-color    : $red_radish;

$nav-sub-active-hover-text-color     : $vivid_cerulean;
$nav-sub-active-hover-border-color   : $vivid_cerulean;

$nav-sub-current-expanded-text-color     : $star_command_blue;
$nav-sub-current-expanded-border-color   : $star_command_blue;

$nav-sub-box-shadow      			: 0px 2px 4px rgba(0, 0, 0, 0.12);
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

// /* MOBILE */ //

$nav-mob-text-color      						: $primary;
$nav-mob-active-text-color      		: $red_radish;
$nav-mob-active-bg-color      			: white;
$nav-mob-active-border-color	  	  : $red_radish;
$nav-mob-active-hover-border-color	: $vivid_cerulean;

$nav-mob-1st-bg-color     					: $sea_blue;
$nav-mob-1st-text-color      				: white;
$nav-mob-1st-hover-bg-color         : $star_command_blue;
$nav-mob-1st-hover-text-color       : $vivid_cerulean;
$nav-mob-1st-highlight-bg-color     : $star_command_blue;
$nav-mob-1st-highlight-text-color   : white;
$nav-mob-1st-border-color     			: $star_command_blue;
$nav-mob-1st-highlight-border-color : white;

$nav-mob-2nd-bg-color     					: $azureish_white;
$nav-mob-2nd-highlight-bg-color     : $azureish_white;
$nav-mob-2nd-highlight-text-color   : $star_command_blue;
$nav-mob-2nd-line-color     				: $yonder_blue;
$nav-mob-2nd-border-color     			: $yonder_blue;
$nav-mob-2nd-text-color      				: $celtis_rush;

$nav-mob-3rd-bg-color     					: $mint-cream;
$nav-mob-3rd-highlight-bg-color     : $mint-cream;
$nav-mob-3rd-highlight-text-color   : $primary;
$nav-mob-3rd-line-color     				: $azureish_white;
$nav-mob-3rd-border-color     			: $azureish_white;
$nav-mob-3rd-text-color      				: $noshime_flower;

// $nav-mob-4th-bg-color     					: $azureish_white;
// $nav-mob-4th-highlight-bg-color     : $blue_bell;
// $nav-mob-4th-highlight-text-color   : $primary;
// $nav-mob-4th-line-color     				: purple;
// $nav-mob-4th-border-color     			: $dark_lavender;

$nav-mob-border											: 1px solid $star_command_blue;
$nav-mob-expanded-border						: 1px solid $star_command_blue;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: 0px 2px 4px rgba(0, 0, 0, 0.12);



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: white;
$nav-button-hover-bg-color			: $star_command_blue;
$nav-button-height      				: 72px;
$nav-button-width      					: 68px;
$nav-button-bar-bg-color      	: $celtis_rush;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 48px;
$nav-button-bar-border-radius 	: 8px;
