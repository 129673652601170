.card-tagline-text {
  font:$tagline-font-smaller;
  max-width: 90%;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  @media(min-width:720px){
    padding: 40px 100px;
    font:$tagline-font;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &:before,
  &:after {
    content:"";
    height: 10px;
    width: 40px;
    display: inline-block;
    position: absolute;
    top:calc(50% - 5px);
    @media(min-width:720px){
      width: 110px;
      height: 16px;
      top:calc(50% - 8px);
    }
  }
  &:before {
    left:-30px;
    @media(min-width:720px){
      left:-40px;
    }
  }
  &:after {
    right:-30px;
    @media(min-width:720px){
      right:-40px;
    }
  }
}

// PALETTE SPECIFIC

.content-main,
.content-additional {
  .card-tagline-text  {
    background:$tagline-bg-default;
    color:$tagline-color-default;
    &:before,
    &:after {
      background:$tagline-accent-default;
    }
  }
}

.postscript-first .section-row {
  &.palette-default {
    .card-tagline-text  {
      background:$tagline-bg-default;
      color:$tagline-color-default;
      &:before,
      &:after {
        background:$tagline-accent-default;
      }
    }
  }
  &.palette-alt-1 {
    .card-tagline-text  {
      background:$tagline-bg-alt-1;
      color:$tagline-color-alt-1;
      &:before,
      &:after {
        background:$tagline-accent-alt;
      }
    }
  }
  &.palette-alt-2 {
    .card-tagline-text  {
      background:$tagline-bg-alt-2;
      color:$tagline-color-alt-2;
      &:before,
      &:after {
        background:$tagline-accent-alt;
      }
    }
  }
  &.palette-alt-3 {
    .card-tagline-text  {
      background:$tagline-bg-alt-3;
      color:$tagline-color-alt-3;
      &:before,
      &:after {
        background:$tagline-accent-alt;
      }
    }
  }
  &.palette-highlight {
    .card-tagline-text  {
      background:$tagline-bg-highlight;
      color:$tagline-color-highlight;
      &:before,
      &:after {
        background:$tagline-accent-highlight;
      }
    }
  }
}