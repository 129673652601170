
#imageDataContainer {
  #imageData {
    #imageDetails {
      width: 100%;

      .card-lightbox {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;

        @media(min-width: 540px) {
          flex-wrap: nowrap;
        }

        .card-lightbox-text {
          .card-lightbox-terms {
            .card-lightbox-term {
              font-weight: normal;
              font-size: 0.611rem;
            }
          }

          .card-lightbox-details {

            & > div {
              color: $noshime_flower;
              font-size: 0.611rem;
              padding: 5px 0 10px;
              margin-right: 10px;
              display: inline-block;
              font-weight: normal;
            }

            .card-lightbox-extension {
              text-transform: uppercase;
              font-weight: 600;
            }
          }
        }

        .card-lightbox-btn {
          margin-bottom: 20px;
        }
      }
    }

    #bottomNav {
      #bottomNavClose {
        margin-top: -35px;
        background:  url(../images/dialog-close.gif) left no-repeat;
        &:hover {
          cursor: pointer;
          background-position: right;
        }
      }

      #bottomNavZoom,
      #bottomNavZoomOut {
        display: none !important;
      }
    }
  }
}
