// /* TAGLINES PALETTE */
$tagline-font                  : 1.778rem/1.4 $title-font-family;
$tagline-font-smaller          : 1.2rem/1.4 $title-font-family;

$tagline-bg-default            :$azureish_white;
$tagline-color-default         :$primary;
$tagline-accent-default        :$sea_blue;

$tagline-bg-alt-1              :white;
$tagline-color-alt-1           :$primary;
$tagline-accent-alt            :rgba(34, 56, 67, 0.2);

$tagline-bg-alt-2              :white;
$tagline-color-alt-2           :$primary;

$tagline-bg-alt-3              :$white;
$tagline-color-alt-3           :$primary;

$tagline-bg-highlight          :$prussian_blue;
$tagline-color-highlight       :white;
$tagline-accent-highlight      :$vivid_cerulean;