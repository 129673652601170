// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $base-font-family;
$card-title-font        	: normal 1.333rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.333rem/1 $base-font-family;
$card-feed-link-font			: bold 1.111rem/1.46153846153846 $base-font-family;


$card-shadow                   : none;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : lighten($platinum,10%);
$card-date-border              : none; //1px solid $mint-cream
$card-date-border-radius       : 0;
$card-date-text-color          : $secondary;
$card-date-font                : 0.8125em/1 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : white;
$card-hover-border             : none;
$card-hover-shadow             : none;
$card-action-hover             : $action-hover;
$card-text-hover-color         : $noshime_flower;
$card-date-hover-text-color    : $noshime_flower;
$card-date-hover-bg-color      : transparent;

$card-hover-bg-color-alt       : $vivid_cerulean;
$card-hover-border-alt         : none;
$card-hover-shadow-alt         : none;
$card-action-hover-alt         : $elephant;
$card-text-hover-color-alt     : $elephant;
$card-date-hover-text-color-alt: $elephant;
$card-date-hover-bg-color-alt  : transparent;

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $sea_blue;
$card-light-action-default     : $sea_blue;
$card-light-action-hover       : $action-hover;
$card-light-text-color         : $celtis_rush;
$card-light-subtitle-color     : $noshime_flower;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $azureish_white;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $sea_blue;
$card-medium-action-default    : $sea_blue;
$card-medium-action-hover      : $action-hover;
$card-medium-text-color        : $celtis_rush;
$card-medium-subtitle-color    : $noshime_flower;

// /* --- CARD-DARK - dark coloured background */ 
$card-dark-bg-color            : $mint-cream_two;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $black;
$card-dark-action-default      : $black;
$card-dark-action-hover        : $action-hover;
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $yonder_blue;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $prussian_blue;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : white;
$card-highlight-action-default : $action-default;
$card-highlight-action-hover   : $action-hover;
$card-highlight-text-color     : $yonder_blue;
$card-highlight-subtitle-color : $yonder_blue;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : white;
$card-more-link-default-color       : $sea_blue;
$card-more-link-default-icon        : $arrow-sea-right no-repeat 100% 100% / 15px auto;
$card-more-link-default-hover-bg    : white;
$card-more-link-default-hover-color : $red_radish;
$card-more-link-default-hover-icon  : $arrow-radish-right no-repeat 100% 100% / 15px auto;

$card-more-link-alt-bg              : $prussian_blue;
$card-more-link-alt-color           : white;
$card-more-link-alt-icon            : $arrow-white-right no-repeat 100% 100% / 15px auto;
$card-more-link-alt-hover-bg        : $vivid_cerulean;
$card-more-link-alt-hover-color     : $elephant;
$card-more-link-alt-hover-icon      : $arrow-elephant-right no-repeat 100% 100% / 15px auto;

$card-more-link-alt-2-bg              : $azureish_white;
$card-more-link-alt-2-color           : $sea_blue;
$card-more-link-alt-2-icon            : $arrow-sea-right no-repeat 100% 100% / 15px auto;
$card-more-link-alt-2-hover-bg        : $azureish_white;
$card-more-link-alt-2-hover-color     : $red_radish;
$card-more-link-alt-2-hover-icon      : $arrow-radish-right no-repeat 100% 100% / 15px auto;


// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none;
$card-feed-link-default-color       : $sea_blue;
$card-feed-link-default-icon        : $arrow-sea-right no-repeat scroll 50% 50% / 15px 17px;
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $red_radish;
$card-feed-link-default-hover-icon  : $arrow-sea-right no-repeat scroll 50% 50% / 15px 17px;

$card-feed-link-alt-bg              : none;
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : $arrow-white-right no-repeat scroll 50% 50% / 15px 17px;
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $sea_blue;
$card-feed-link-alt-hover-icon      : $arrow-white-right no-repeat scroll 50% 50% / 15px 17px;

$card-feed-link-alt-2-hover-bg        : none;
$card-feed-link-alt-2-hover-color     : $lilac;
$card-feed-link-alt-2-hover-icon      : $arrow-lilac-right no-repeat scroll 50% 50% / 15px 17px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $action-default;
$button-link-default-border-color       : $action-default;
$button-link-default-color              : white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $action-hover;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : none;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $grey;
$button-link-alt-border-color           : $grey;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $action-hover;
$button-link-alt-hover-border-color     : $action-hover;
$button-link-alt-hover-color            : white;
$button-link-alt-hover-icon             : none;