.ui-dialog {
  &.ui-widget.ui-widget-content {
    border: none;

    .ui-widget-header {
      background-color: white;
      border: none;

      .ui-dialog-title {
        font: $card-title-font;
        color: $sea_blue;
      }

      .ui-dialog-titlebar-close {
        border: none;
        margin-top: -15px;
        width: 26px;
        height: 26px;
        background: none;

        .ui-button-icon {
          background: white url(../images/dialog-close.gif) left no-repeat;
          height: 26px;
          width: 26px;
          margin-left: -15px;
          margin-top: -15px;
        }

        &:hover {
          .ui-button-icon {
            cursor: pointer;
            background-position: right;
          }
        }
      }
    }
  }
}