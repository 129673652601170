#sliding-popup .popup-content {

  #popup-buttons,
  .eu-cookie-compliance-categories-buttons {

    button {
      border: 2px solid white;
      background: transparent;
      color: white;
      border-radius: 0;
      box-shadow: none;
      text-shadow: none;

      &:hover {
        background: white;
        color: #0779bf;
      }
    }
  }

  #popup-buttons {
    max-width: calc(100% - 180px);
    display: flex;
    flex-wrap: wrap;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .eu-cookie-compliance-categories {
    clear: both;
    padding-top: 0;
  }

  #popup-text {
    margin: 1rem 0;
    font-weight: 700;

    max-width: calc(100% - 60px);

    @include media($narrow) {
      max-width: calc(100% - 90px);
    }

    h2 {
      font: normal 1em/1.2 $title-font-family;

      @include media($narrow) {
        font-size: 1.25em;
      }
    }

    p {
      font: normal 0.875em/1.286 $base-font-family;

      a {
        text-decoration: underline;
        color: white;
        &:hover {
          color: $platinum;
        }
      }
    }
  }
}
