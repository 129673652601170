/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
	
	.views-row {
		margin-bottom: 10px;
	}
	
	.card-qa-answer {
		display: none;
		padding: 0px 0 0 40px;	
		box-sizing: border-box;
		color:$celtis_rush;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		color: $star_command_blue;
		padding: 10px 0 10px 40px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		font-size: 1.222em;
		background: $expand-star no-repeat scroll 0 50% / 20px 20px transparent;
		&:hover {
			color:$red_radish;
			background: $expand_radish no-repeat scroll 0 50% / 20px 20px transparent;
		}
		&.active {
			background: $collapse-star no-repeat scroll 0 50% / 20px 20px transparent;
			&:hover {
				background: $collapse-radish no-repeat scroll 0 50% / 20px 20px transparent;
			}
		}
		p {
			margin-bottom: 0;
		}
	}
}

// Palette specific

.section-row {
	&.palette-alt-1 {
		.view-faqs {
			.card-qa-question {
				color: white;
				background: $expand-white no-repeat scroll 0 50% / 20px 20px transparent;
				&:hover {
					color:$sea_blue;
					background: $expand-sea no-repeat scroll 0 50% / 20px 20px transparent;
				}
				&.active {
					background: $collapse-white no-repeat scroll 0 50% / 20px 20px transparent;
					&:hover {
						background: $collapse-sea no-repeat scroll 0 50% / 20px 20px transparent;
					}
				}
			}
		}
	}
	&.palette-alt-2 {
		.view-faqs {
			.card-qa-answer {
				color:white;
			}
			.card-qa-question {
				color: white;
				background: $expand-white no-repeat scroll 0 50% / 20px 20px transparent;
				&:hover {
					color:$lilac;
					background: $expand-lilac no-repeat scroll 0 50% / 20px 20px transparent;
				}
				&.active {
					background: $collapse-white no-repeat scroll 0 50% / 20px 20px transparent;
					&:hover {
						background: $collapse-lilac no-repeat scroll 0 50% / 20px 20px transparent;
					}
				}
			}
		}
	}
	&.palette-alt-3 {
		.view-faqs {
			.card-qa-question {
				color: white;
				background: $expand-white no-repeat scroll 0 50% / 20px 20px transparent;
				&:hover {
					color:$sea_blue;
					background: $expand-sea no-repeat scroll 0 50% / 20px 20px transparent;
				}
				&.active {
					background: $collapse-white no-repeat scroll 0 50% / 20px 20px transparent;
					&:hover {
						background: $collapse-sea no-repeat scroll 0 50% / 20px 20px transparent;
					}
				}
			}
		}
	}
}