button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.views-submit-button {
  input#edit-submit-ipcrg-res-pub,
  input#edit-submit-ipcrg-gallery {
    text-indent: -9999px;
    height: 60px;
    width: 60px;
    margin-top: 106px;
    margin-left: -20px;
    background: $search-white no-repeat 50% 50% / 30px 30px $red_radish;
    &:hover {
      background-color:$sea_blue;
    }
    @media(min-width: 720px) {
      margin-top: 126px
    }
  }
}