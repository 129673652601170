fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: none;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;
	height: 60px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background:$ico-filter-arrow no-repeat 100% 100% / 44px 44px white;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: none;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	height: 44px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

input.admin-menu-search.form-text {
    height: 1rem;
}