.highcharts-root * {
  font-family: $highcharts-font-family !important;
}
#map-world-wrapper {
  margin: $highcharts-container-margin;
} 

.map-wrapper {
  height:260px;
  @media(min-width:540px) {
    height:360px;
  }
  @media(min-width:960px) {
    height:540px;
  }
  @media(min-width:1200px) {
    height:720px;
  }
}


// MAPS
.map-wrapper {
	.highcharts-container {
		.highcharts-root{
			font-family:$base-font-family;
			font-size:18px;
		}
		// Ocean
		.highcharts-background {
				fill:#FFFFFF;
		}
		// Empty countries
		.highcharts-series-group .highcharts-map-series {
			.highcharts-null-point {
				//fill:white;
				stroke:#C1C4C7;
			}
		}
		// Buttons
		.highcharts-button.highcharts-map-navigation {
			path {
        fill:$red_radish;
        stroke:$red_radish;
			}
			text {
				stroke:white !important;
				fill:white !important;
			}
			&.highcharts-button-hover {
				path {
          fill:$vivid_cerulean;
  				stroke:$vivid_cerulean;
				}
				text {
					stroke:white!important;
					fill:white!important;
				}
			}
		}
		//Tooltip label
		.highcharts-tooltip {
			padding:21px;
			//border-left: 2px solid red;
			path.highcharts-label-box.highcharts-tooltip-box {
				fill: lighten($pale_grey, 3%);
				stroke:$pale_grey;
				box-shadow:$box-shadow;
			}
		}
	//	Tooltip text
		div.highcharts-label.highcharts-tooltip span {
			font-family:$base-font-family !important;
			font-size:18px !important;
		}
	}
}