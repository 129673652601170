// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: $arrow-radish-left no-repeat scroll 40% 50% / 17px 15px;
$slick-default-prev-hover-bg      				: $arrow-sea-left no-repeat scroll 40% 50%/ 17px 15px;
$slick-default-next-bg            				: $arrow-radish-right no-repeat scroll 60% 50%/ 17px 15px;
$slick-default-next-hover-bg      				: $arrow-sea-right no-repeat scroll 60% 50%/ 17px 15px;
$slick-default-dot-border         				: 1px solid $vivid_cerulean;
$slick-default-dot-hover-border    				: 1px solid $red_radish;
$slick-default-dot-active-bg-color 				: $sea_blue;
$slick-default-dot-active-border       	  : 1px solid $sea_blue;
$slick-default-dot-active-hover-bg       	: $red_radish;
$slick-default-dot-active-hover-bg-color 	: $red_radish;
$slick-default-border             				: solid 2px $red_radish;
$slick-default-hover-border             	: solid 2px $sea_blue;


$slick-alt-1-nav-bg                				: transparent;
$slick-alt-1-controls-bg           				: none;
$slick-alt-1-prev-bg               				: $arrow-white-left no-repeat scroll 40% 50% / 17px 15px;
$slick-alt-1-prev-hover-bg         				: $arrow-winter-left no-repeat scroll 40% 50% / 17px 15px;
$slick-alt-1-next-bg               				: $arrow-white-right no-repeat scroll 60% 50% / 17px 15px;
$slick-alt-1-next-hover-bg         				: $arrow-winter-right no-repeat scroll 60% 50% / 17px 15px;
$slick-alt-1-dot-default-border    				: 1px solid white;
$slick-alt-1-dot-hover-border      				: 1px solid $winter_wizard;
$slick-alt-1-dot-active-bg-color   				: $prussian_blue;
$slick-alt-1-dot-active-hover-bg-color 		: $winter_wizard;
$slick-alt-1-dot-active-border         	  : 1px solid $prussian_blue;
$slick-alt-1-border             			  	: solid 2px white;
$slick-alt-1-hover-border               	: solid 2px $winter_wizard;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $arrow-white-left no-repeat scroll 40% 50% / 17px 15px;
$slick-alt-2-prev-hover-bg         				: $arrow-lilac-left no-repeat scroll 40% 50% / 17px 15px;
$slick-alt-2-next-bg               				: $arrow-white-right no-repeat scroll 60% 50% / 17px 15px;
$slick-alt-2-next-hover-bg         				: $arrow-lilac-right no-repeat scroll 60% 50% / 17px 15px;
$slick-alt-2-dot-default-border    				: 1px solid white;
$slick-alt-2-dot-hover-border      				: 1px solid $lilac;
$slick-alt-2-dot-active-bg-color   				: $white;
$slick-alt-2-dot-active-hover-bg-color 		: $lilac;
$slick-alt-2-dot-active-border         	  : 1px solid $white;
$slick-alt-2-border             			  	: solid 2px white;
$slick-alt-2-hover-border               	: solid 2px $lilac;

$slick-alt-3-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-3-controls-bg         					: none; // used on twitter views etc
$slick-alt-3-prev-bg               				: $arrow-white-left no-repeat scroll 40% 50% / 17px 15px;
$slick-alt-3-prev-hover-bg         				: $arrow-apricot-left no-repeat scroll 40% 50% / 17px 15px;
$slick-alt-3-next-bg               				: $arrow-white-right no-repeat scroll 60% 50% / 17px 15px;
$slick-alt-3-next-hover-bg         				: $arrow-apricot-right no-repeat scroll 60% 50% / 17px 15px;
$slick-alt-3-dot-default-border    				: 1px solid white;
$slick-alt-3-dot-hover-border      				: 1px solid $apricot;
$slick-alt-3-dot-active-bg-color   				: $prussian_blue;
$slick-alt-3-dot-active-hover-bg-color 		: $apricot;
$slick-alt-3-dot-active-border         	  : 1px solid $prussian_blue;
$slick-alt-3-dot-active-hover-bg        	: $apricot;
$slick-alt-3-border             			  	: solid 2px white;
$slick-alt-3-hover-border               	: solid 2px $apricot;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgba(255, 255, 255, 0.7) ;
$slide-text-color          : $primary;
$slide-text-font           : 1.375rem/1.45 $title-font-family;

// SLIDESHOWS

//$slide-text-bg-color                      : $space_cadet;
//$slide-text-alt-2-bg-color                : $tobiko_orange;
$slide-text-color                         : $white;
$slide-text-font                          : 1.777rem/1.45 $title-font-family;

$slide-default-nav-bg              				: transparent; // used on slide and galler views
$slide-default-controls-bg         				: none; // used on twitter views etc
$slide-default-prev-bg             				: transparent $arrow-white-left no-repeat scroll 40% 50% / 17px 15px;
$slide-default-prev-hover-bg      				: transparent $arrow-cerulean-left no-repeat scroll 40% 50% / 17px 15px;
$slide-default-next-bg            				: transparent $arrow-white-right no-repeat scroll 60% 50% / 17px 15px;
$slide-default-next-hover-bg      				: transparent $arrow-cerulean-right no-repeat scroll 60% 50% / 17px 15px;
$slide-default-dot-border         				: 1px solid white;
$slide-default-dot-hover-bg-color       	: $vivid_cerulean;
$slide-default-dot-hover-border    				: 1px solid $vivid_cerulean;
$slide-default-dot-active-bg-color 				: white;
$slide-default-dot-active-border  				: 1px solid white;
$slide-default-border             			  : solid 2px white;
$slide-default-hover-border               : solid 2px $vivid_cerulean;