// /* HEADER */ //

$header-menu-font 				: /*0.875rem*/ 1rem/140% $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-family;

$header-bg-color                   : none;

$header-menu-link-color            : $secondary;
$header-menu-link-hover            : $dogwood_rose;

$header-search-field-bg-color      : $mint-cream;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;