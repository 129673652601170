#shareLinks {
  background: white;
  border: 1px solid transparent transparent blue transparent;
  border-radius: 3px;
  box-shadow: none;
  margin: 0 0 25px;
  padding: 20px 20px;
  overflow: hidden;
  .share-wrapper {
    display: flex;
    flex-wrap: wrap;
    a {
      display: block;
      height: 30px;
      width: 30px;
      text-indent:-999em;
      overflow:hidden;
      background-color: green;
      margin-right: 20px;
      margin-bottom: 20px;
      &.twitter {
        background: $ico-twitter-blue no-repeat 50% 50% / 100%;
        &:hover {
          background: $ico-twitter-red no-repeat 50% 50% / 100%;
        }
      }
      &.facebook {
        background: $ico-facebook-blue no-repeat 50% 50%;
        &:hover {
          background: $ico-facebook-red no-repeat 50% 50%;
        }
      }
      &.linkedin {
        background: $ico-linkedin-blue no-repeat 50% 50%;
        &:hover {
          background: $ico-linkedin-red no-repeat 50% 50%;
        }
      }
      &.whatsapp {
        background: $ico-whatsapp-blue no-repeat 50% 50%;
        &:hover {
          background: $ico-whatsapp-red no-repeat 50% 50%;
        }
      }
      &.email {
        background: $ico-newsletter-blue no-repeat 50% 50%;
        &:hover {
          background: $ico-newsletter-red no-repeat 50% 50%;
        }
      }
    }
  }
}
