.ajax-progress, ajax-progress-throbber, .flag-throbber {
  display: none;
}

body {
  .view {
    .view-filters {
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
    }

    // .view-content {
    // 	opacity:1;
    // 	transition:opacity 0.5s ease-in-out;
    // }
  }

  &.ajax-processing {
    .view {
      .view-filters {
        opacity: 0.5;
      }

      .view-content {
        opacity: 0;
      }

      * {
        cursor: wait;
      }
    }
  }
}

html.js {
  fieldset.collapsed,
  fieldset.collapsible {
    .fieldset-legend {
      background: none;
    }
  }
}

.views-exposed-form {
  margin-bottom: 130px;
  background: $pattern no-repeat 0% 20% / 858px 202px $prussian_blue;
  background-position-y: 16px;
  @media(min-width: 898px) {
    background-position: 100% 20%;
    background-position-y: 16px;
  }

  .views-exposed-widgets {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    position: relative;
  }

  #edit-keywords-wrapper {
    order: 1;
    padding: 30px 10px;
    width: calc(100% - 55px);
    @media(min-width: 720px) {
      padding: 40px 20px;
      width: 25%;
      min-width: 400px;
    }

    label {
      font-size: 1.444em;
      margin: 0 0 10px !important;
    }

    .form-item-keywords input.form-text {
      &::-webkit-input-placeholder {
        color: $primary;
        font-size: 0.777em;
        padding: 3px 10px 6px;
        margin: 0;
        margin-top: -5px;
      }
    }
  }

  .views-exposed-widget.views-submit-button {
    order: 2;
    flex: 0;
  }

  .views-exposed-widget.views-reset-button {
    order: 3;
    flex: 0;
    width: 107px;

    input#edit-reset {
      background: $vivid_cerulean;
      height: 60px;
      margin-top: -20px;
      margin-bottom: 20px;

      &:hover {
        background: $sea_blue;
      }

      @media(min-width: 720px) {
        margin-top: 126px;
        margin-left: 20px;
      }
    }
  }

  #edit-secondary-wrapper {
    order: 4;
    flex: 1 100%;
    padding: 0;

    fieldset.collapsible {
      height: auto;
      padding: 0;
      margin: 0;
    }

    .collapsed legend .fieldset-legend a.fieldset-title {
      background: $expand-white no-repeat 200px 50%/auto 30px $sea_blue;
    }

    legend {
      width: 100%;

      .fieldset-legend {
        width: 100%;
        padding: 0;
        height: 56px;

        a.fieldset-title {
          background: $collapse-white no-repeat 200px 50%/30px 30px $sea_blue;
          border-top: solid 2px white;
          width: 100%;
          color: white;
          margin-bottom: 30px;
          display: block;
          font-size: 1.444em;
          padding: 8px 60px 8px 15px;

          &:hover {
            color: white;
          }
        }
      }
    }

    .bef-secondary-options {
      padding: 30px 30px 65px;
      background: $azureish_white;
      margin: -2px;
      //margin-top: 24px;
      .filter-wrapper {
        clear: both;
        width: 100%;
        display: inline-block;
      }

      label {
        display: none;
      }
    }
  }

  label {
    color: white;
    font: normal 1.111em/1.3 $base-font-family;
    margin: 10px 0;
  }

  .views-exposed-widget .form-submit {
    margin-top: 29px;
  }

  // SORT FILTER

  .views-exposed-widget.views-widget-sort-by {
    position: absolute;
    bottom: -85px;
    //left: 20px;
    left: auto;
    width: 100%;
    @media(min-width: 540px) {
      right: 0;
      width: 320px !important;
      //left: auto;
    }

    .form-item-sort-by {
      label {
        color: $primary;
        display: inline;
        font-size: 1em;
      }

      select#edit-sort-by {
        display: inline;
        //width: calc(100% - 118px);
        width: calc(100% - 78px);
        margin: 0 0 0 20px;
        background: $ico-filter-arrow no-repeat 100% 100% / 44px 44px white;
        @media(min-width: 540px) {
          width: 220px;
          margin: 0 20px;
        }
      }
    }
  }

  .views-exposed-widget.views-widget-sort-order {
    display: none;
  }

  .container-inline-date .form-item {
    width: 100%;

    select#edit-from-value-year,
    select#edit-to-value-year {
      background: $ico-filter-calender no-repeat 100% 100% / 44px 44px white;
    }

    .date-padding {
      width: 100%
    }
  }
}

// RESPONSIVE STYLING

.bef-secondary-options {
  .form-item {
    float: left;
    width: 100%;
    margin-bottom: 15px;

    &:nth-child(8) {
      margin-bottom: 0;
    }

    @media(min-width: 400px) and (max-width: 1199px) {
      width: calc(50% - 10px);
      margin-right: 20px;
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(8) {
        margin-right: 0;
      }
      &:nth-child(7) {
        margin-bottom: 0;
      }
    }
    @media(min-width: 1200px) and (max-width: 1599px) {
      width: calc(33.3% - 13px);
      margin-right: 20px;
      &:nth-child(3),
      &:nth-child(6) {
        margin-right: 0;
      }
    }
    @media(min-width: 1600px) {
      width: calc(25% - 15px);
      margin-right: 20px;
      &:nth-child(4) {
        margin-right: 0;
      }
    }
  }

  #edit-field-date-value-value-wrapper {
    display: inline-block;
    width: 50%;
    height: 44px;
    padding-right: 20px;
    border-top: solid 1px white;

    &:last-child {
      padding-right: 0;
    }

    @media(min-width: 720px) {
      width: calc(25% - 5px);
      margin-top: 5px;
    }
    @media(min-width: 1200px) {
      width: calc(17% - 10px);
      margin-top: -10px;
    }
    @media(min-width: 1600px) {
      width: calc(13% - 15px);
    }
  }
}

.views-exposed-form {
  .views-exposed-widget {
    width: 100%;
    padding: 0;

    &.views-reset-button {
      min-width: 110px;
      @media(max-width: 719px) {
        left: calc(100% - 120px);
        position: relative;
      }
    }

    &.views-submit-button,
    &.views-reset-button {
      width: 100%;

      .form-submit {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }

    &.views-reset-button .form-submit {
      margin-bottom: 0;
    }

    select {
      width: 100%;
    }
  }
}

.postscript-first .custom-filters.section-row .section-inner {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0;

  .view-content {
    padding: 0 20px;
  }
}

.view-ipcrg-res-pub,
.view-ipcrg-gallery {
  display: flex;
  flex-flow: column wrap;
  position: relative;

  .view-filters {
    order: 1;
  }

  .view-header {
    order: 2;
    font-size: 1.111em;
    display: inline-block;
    width: auto;
    margin-top: -30px;
    margin-left: 20px;
    @media(min-width: 540px) {
      width: 180px;
      margin-top: -80px;
    }
  }

  .view-content.view-content-main {
    order: 3;
    margin-top: 30px;
    clear: both;
  }

  .item-list {
    order: 4;
    display: inline-block;
    clear: both;
    margin-top: 100px;
    @media(min-width: 540px) {
      margin-top: 0;
    }
  }
}

// search resources page

.search-page {
  display: flex;
  flex-direction: column;
  position: relative;
  @media(min-width: 720px) {
    flex-direction: row;
  }

  .aside-filter-wrapper {
    padding: 0 20px;
    @include media($narrow) {
      padding: 0 10px;
      width: 30%;
    }
    @include media($normal) {
      width: 25%;
    }

    .views-exposed-form {
      padding: 0;
      background: none;

      .views-exposed-widgets {
        position: static;
      }
    }

    .views-exposed-widget-submit {
      width: 100%;
      background: white;
      padding: $vert-space/2 $horz-space $vert-space;
    }

    .views-exposed-widget-sort {
      order: 5;
      margin-bottom: -10px;
      width: 100%;

      @media(min-width: 540px) {
        margin-left: calc(100% - 300px);
        right: 0;
      }

      @media(min-width: 720px) {
        position: absolute;
        max-width: 200px;
        right: 0;
      }

      .views-widget-sort-by {
        width: 100%;
        top: 0;
        position: relative;

        @media(min-width: 720px) {
          position: absolute;
        }
      }

      label {
        display: none;
      }

      select:not(#edit-sort-by) {
        width: 100% !important;
        margin-right: $horz-space*2;
      }
    }
  }

  .content-wrapper {
    margin-top: 30px;
    max-width: 100%;
    @media(min-width: 540px) {
      margin-top: -40px;
    }
    @include media($narrow) {
      margin-top: 3px;
      //width: calc(70% - 20px);
      width: 70%;
    }
    @include media($normal) {
      width: 75%;
    }

    .header-wrapper {
      margin-top: 5px;

      .view-header {
        margin-top: 0;
      }
    }

    .view-content {
      margin-top: 50px;
      @media(min-width: 720px) and (max-width: 960px) {
        .views-row {
          width: 100%;
          margin-right: 0;
        }
      }

      @media(min-width: 960px) {

      }
    }

    .item-list {
      width: 100%;
    }
  }

  &.masonry-view {
    .content-wrapper {
      .view-content {
        margin: 50px 20px 0 20px;
        padding: 0 !important;

        @media(min-width: 520px) {
          margin: 50px 0 0 20px;
        }

        &.view-ipcrg-gallery-content {
          .views-row {
            @media(min-width: 520px) and (max-width: 960px) {
              margin-right: 2.35765%;
              width: calc((100% / 2) - (2.35765% * 1.25));
              clear: left;

              &.views-row-even {
                margin-right: 0;
                clear: none;
              }
            }
            @media(min-width: 960px) {
              margin-right: 2.35765%;
              width: calc((100% / 3) - (2.35765%));
            }
          }
        }
      }
    }
  }

  #edit-keywords-wrapper {
    width: 100%;
    background: $sea_blue;
    padding: $vert-space $horz-space;
    min-width: auto;

    input[type="text"] {
      background: white;
    }
  }


  .views-exposed-form {
    margin-bottom: 0;
    background: none !important;

    #edit-keywords-wrapper {
      order: 1;
    }

    .views-exposed-widget-submit {
      order: 2;
      width: 100%;
      padding: 0 0 20px 0;
      background: none;

      .views-exposed-widget {
        &.views-submit-button {
          margin-top: -90px;
          margin-left: calc(100% - 80px);

          .form-submit {
            margin: 0 !important;

            &:hover {
              background-color: $prussian_blue !important;
            }
          }
        }

        &.views-reset-button {
          left: 0 !important;

          .form-submit {
            margin: 0 !important;
            min-width: auto;

            &:hover {
              background-color: $prussian_blue !important;
            }
          }
        }
      }
    }


    .exposed-filter-summary {
      order: 3;
      width: 100%;

      ul {
        margin: 0 0 20px;
        padding: 0;
        background: $azureish_white;
      }

      li {
        padding: 0 $horz-space;

        &.list-group-item:first-child {
          padding-top: $vert-space;
        }

        &.list-group-item:last-child {
          padding-bottom: 25px;
        }

        .list-group-item-text {
          font-weight: bold;
          margin: $vert-space/2 0 $vert-space/4;
        }

        .remove {
          font-size: 21px;
          font-weight: 500;
          cursor: pointer;
          margin-right: 0px;
          width: 20px;
          padding: 0 $horz-space/4;

          &:hover {
            color: $red_radish;
          }
        }
      }
    }

    #edit-secondary-wrapper {
      order: 4;
      width: 100%;

      fieldset.collapsible {
        border: none;
        padding: 0;
      }

      .fieldset-legend {
        display: none;
      }

      .fieldset-wrapper {
        padding: 0;

        legend {
          width: 100%;
        }

        & > div {
          padding: $vert-space 0;
          background-color: $azureish_white;
        }

        .bef-secondary-options {
          padding: 0;
          margin: 0;
          background: none;

          .filter-wrapper {
            padding: 0;
          }

          .form-item {
            width: 100%;
            margin: 0 0 $vert-space;

            .form-wrapper {
              padding: 0;
              margin: 0;

              .form-item {
                margin: 0;
                padding: $vert-space/4 $horz-space;

                label {
                  font-size: 1rem;
                  color: inherit;
                  font-size: 1rem;
                  display: inline;
                }
              }
            }

            &.form-type-textfield {
              padding: 15px $horz-space;
              margin-bottom: 0;
            }

            &.form-item-content-type {
              margin-top: $vert-space;
            }

            &.form-item-any-word {
              margin-top: -20px;
            }
          }

          #edit-field-date-value-value-wrapper {
            border: none;
            width: calc(50% - 10px);
            padding: 0;
            margin: 0 20px 0 0;
            height: auto;

            &:last-child {
              margin: 0;
            }

            label {
              margin: 0 0 10px;
            }
          }
        }

        .fieldset-legend {
          display: inline-block !important;
          width: 100%;
          padding: 0px;

          a {
            background-position: calc(100% - 15px) 50% !important;
          }
        }

        .summary {
          display: none;
        }
      }
    }

    .bef-checkboxes {
      max-height: 300px;
      overflow: auto;
      margin: 0 $horz-space/2 0 0;
    }
  }

}

// DATE FILTER LABELS
.views-exposed-form #edit-secondary-wrapper .bef-secondary-options {
  #edit-from-value,
  #edit-to-value {
    label {
      display: block;
      color: transparent;
      position: relative;

      &:after {
        display: block;
        color: $secondary;
        height: 1em;
        content: "From";
        width: 100%;
        position: absolute;
        top: 0;
      }
    }
  }

  #edit-to-value label:after {
    content: "To";
  }
}
