.credit,
.vid-description {
	background: rgba(255, 255, 255, 0.75) none repeat scroll 0 0;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 0.75em;
	margin-bottom: -27px;
	margin: 5px 30px 0 0;
	padding: 10px 30px 10px 20px;
	position: absolute;
	right: -10px;
	text-align: right;
	top: 0;
	
	&:empty {
		display: none;
	}

	&.collapsed {
		background: $vivid_cerulean $ico-info no-repeat 50% 50%;
		height: 50px;
		margin-top: 0px;
		text-align: inherit;
		text-indent: -9999px;
		transition: background-color 0.5s linear;
		width: 40px;
		border-radius: 40px;

		&:hover {
			background-color: $star_command_blue;
		}
	}
}