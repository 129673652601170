// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #223843; /* elephant */
$secondary         : #2D4B5A; /* grey */



// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */ /// MOBILE MENU
$light_blue        : #84D0F5; /* lightblue */
$faded_blue        : #5D86C5; /* faded_blue */ /// MOBILE MENU
$middle_red_purple : #38023B; /* middle_red_purple */

$sea_blue          : #005D99; /* primary */
$vivid_cerulean    : #00A6FB; /* secondary */   /* BG-alt-1 */
$red_radish        : #EE343F; /* tertiary */

// /* SEC PALETTE */ //
$mint-cream_two     : #e4e8eb; /* pale_grey_two */
$battleship_grey   : #6b7280; /* battleship_grey */
$japanese_indigo   : #6b7280; /* japanese_indigo */
$platinum          : #e0e0e2; /* platinum */
$pearl_aqua        : #81D2C7; /* pearl_aqua */  /// MOBILE MENU
$dark_lavender     : #6250A1; /*  dark_lavender  */  /// MOBILE MENU
$pale_grey         : #f5f8fa; /* pale_grey */

// TEXT

$star_command_blue : #0077B8; /* link */
$elephant          : #223843; /* text-titles */
$celtis_rush       : #2D4B5A; /* text-body */
$noshime_flower    : #3F677B; /* text-info */
$yonder_blue       : #9FB2C7; /* alt info */
$lilac             : #C3A9C9; /* alt-1 link hover */
$winter_wizard     : #99DBFD; /* alt-2 hovers */
$apricot           : #F9D1AB; /* alt-3 hovers */


// PALETTES

$mint-cream        : #F5F8FA; /* BG-default/higlight */
$midnight          : #6A2977; /* BG-alt-2 */
$cadmium_orange    : #F18C2C; /* BG-alt-3 */

$azureish_white    : #DDE9F7; /* box-light */
$prussian_blue     : #003455; /* box-dark */


$black             : #000;
$grey              : #666;
$white             : #FFF;

// MENU

$jelly_bean					: #E46747;  /// MOBILE MENU

$middle_red					: #E28871;

$vivid_tanherine		: #EFA491;
$champagne_pink			: #F8D9D1;

$thulian_pink				: #E8789D;
$queen_pink					: #F5C7D6;

$pale_cerulean			: #9EB6DC;
//$azureish_white			: #D6E1F0;

$blue_bell					: #A196C7;
$languid_lavander		: #D8D3E7;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $star_command_blue;
$action-hover        : $red_radish;
$action-active       : $red_radish;
$action-active-hover : $red_radish;


// /* SHADOWS */ //
$box-shadow : 0 2px 4px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $primary-border-color;
$table-cell-border        : 1px solid $mint-cream;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $mint-cream;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : none;
$form-box-shadow-focus : none;
$form-border-color     : $secondary-border-color;
$form-border           : none;
$form-border-radius    : 0;

$button-bg             : $star_command_blue;
$button-hover-bg       : $action-hover;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $mint-cream !default;
