.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
		border-bottom: 4px solid $sea_blue;
		@include media(880px) {
			border-bottom: 0;
		}
	}
	.header-outer-wrapper {
		position:relative;
	}

	.header-inner-wrapper {
		@include outer-container($site-max-width);
		position: relative;
    display: flex;
    flex-direction: column-reverse;
		.block-search {
			display: none;
		}
    .header-content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .slogan-social-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .slogan {
          width: 100%;
        }
      }
    }

    .social-links {
      display: none;
      @media(min-width: 992px) {
        display: inline-block;
      }
      ul {
        display: flex;
        .card-links a {
          text-indent: -9999px;
          border: solid 2px $sea_blue;
          background-position: 50% 50% !important;
          height: 36px;
          width: 36px;
          border-radius: 50%;
          margin-right: 5px;
          padding: 0;
        }
      }
    }
	}
	#main-navigation {
		@include outer-container($site-max-width);
		position: relative;
		.search-toggle-wrapper {
			position: absolute;
			top:-72px;
			right: 70px;
			display: block;
			@media(min-width: 880px) {
				top:0;
				right: 0;
			}
			.search-toggle {
				height: 72px;
				width: 72px;
				background: $search-prussian no-repeat 50% 50%/ 40px 40px transparent;
				text-indent: -9999px;
				&:hover,
				&.active {
					background: $search-white no-repeat 50% 50%/ 40px 40px $star_command_blue;
					color: white;
					@media(min-width: 880px) {
						background: $search-white no-repeat 50% 50%/ 20px 20px $star_command_blue;
					}
				}
				@media(min-width: 880px) {
					height: 40px;
					width: 40px;
					background: $search-white no-repeat 50% 50%/ 20px 20px transparent;
				}
				@media(min-width: 1151px) {
					height: 67px;
					width: 50px;
				}
			}
		}
		.block-search {
			position: absolute;
			display: block;
			right: 0;
			@media(max-width: 879px) {
				width:100%
			}
			#search-block-form--2 {
				display: none;
				position: absolute;
				right: 0;
				top: 0;
				background: $sea_blue;
				z-index: 1;
				padding: 10px;
				height:60px;
				max-width: none;
				@media(max-width: 879px) {
					width:100%
				}
				.form-item {
					margin:0;
					@media(max-width: 879px) {
						width:100%
					}
				}
				.form-item-search-block-form input.form-text {
					width:320px;
					background: $sea_blue;
					border-bottom: solid 2px white;
					height: 40px;
					color: white;
					@media(max-width: 879px) {
						width:100%
					}
					&::placeholder {
						color:white;
					}
				}
				.form-wrapper {
					display:block;
					@include media(880px) {
						display:block;
					}
				}
				input[type="submit"] {
					background: $search-white no-repeat 50% 50%/ 16px 16px transparent;
				}
				@media(min-width: 880px) {
					top: 40px;
				}
				@media(min-width: 1151px) {
					top:67px;
				}
			}
		}
	}

	.site-branding {
		position: relative;
		width: 100%;
    display: flex;
		@include media(880px) {
			display: flex;
      align-items: center;
			padding: 0 1.111rem;
			float: left;
		}
		//@include media(1200px) {
		//	width: auto;
		//}
		.site-title {
			position: relative;
			float: left;
			margin: 70px 0 20px 20px;
			@include media(880px) {
				margin: 1.111rem 1.111rem 1.111rem 0;
			}
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width: 140px;
		    height: 99px;
			}
		}
		.site-slogan {
			//padding-top:35px;
			text-align: center;
			font-size:0.889rem;
			font-weight: normal;
			position: relative;
			float: left;
			display: none;
			transition: all 0.5s ease;
			color:$celtis_rush;
			@include media(880px) {
				display: block;
				margin: 0 5%;
			}
			@include media(1200px) {
				font-size:1.22em;
				margin-left: 5rem;
			}
			//@include media(1600px){
			//	padding-top:3rem;
			//}
			&:after,
			&:before {
				display: inline-block;
				content:"";
				height: 2px;
				width:20px;
				margin-bottom: 6px;
				background:$red_radish;
			}
			&:before {
				margin-right:10px;
			}
			&:after {
				margin-left:10px;
			}
		}
	}
	.block-search {
		float:right;
		height:10rem;
		display: none;
		@include media(880px) {
			height:auto;
		}
		h2 {
			font-family:$base-font-family;
			font-size:1rem;
			color:$primary;
			float:left;
			line-height:1.5;
			margin-top:1rem;
			margin-right:0.75rem;
			cursor:pointer;
			display:none;
			@include media(880px) {
				margin-right:2.5rem;
			}
		}
		.form-wrapper {
			display:none;
			@include media(880px) {
				display:block;
			}
		}
		.form-item-search-block-form {
			float:left;
		}
		.form-actions {
			float:left;
			margin-top:0.75rem;
			@include media(880px) {
				margin-bottom:5rem;
			}
			input.form-submit {
				background-size:14px;
				width:16px;
				height:16px;
				text-indent:-9999em;
				overflow:hidden;
				padding:0;
				position:absolute;
				right:1.25rem;
				top:1.25rem;
			}
		}
	}
	.block-menu {
		position: absolute;
		right: 0;
    @media(min-width: 540px) {
      position: relative;

    }

		li {
			display:inline-block;
			margin:0 1.111rem 0 0;
		}

		a {
			font:$header-menu-font;
			color:$header-menu-link-color;

			&:hover {
				color:$header-menu-link-hover;
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}
