.view-attachments,
.view-display-id-files {
	padding-top: 20px;
			
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: 15px 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		background: $azureish_white;
		overflow: visible;
		margin-bottom:30px;
		position:relative;
		padding:20px;
		
		.file-icon {
			position: absolute;
			top:-29px;
			left:20px;
			display: inline-block;
			margin:0 $horz-space 0 0;
			.file-extension {
				padding:4px 12px 6px;
				text-transform:lowercase;
				font-size:1.111em;
        line-height:1;
				text-align:center;
				font-weight:bold;
			}
		}
		
		.file-size {
			font-size:0.777em;
			color:$primary;
		}
		
		.file-name {
			overflow:hidden;
			font-size: 1.333em;
			color:$sea_blue;
			margin-bottom:15px;
		}
		&:hover {
			.file-name {
				color:$red_radish;
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
} 

// PALETTE SPECIFIC STYLES

.palette-highlight {
	.view-attachments .attachment-link {
		background: $prussian_blue;
		.file-name {
			color:white;
		}
		.file-size {
			color:$yonder_blue;
		}
		&:hover {
			background: $vivid_cerulean;
			.file-name {
				color:$primary;
			}
			.file-extension {
				background-color:white;
				color:$vivid_cerulean;
			}
			.file-size {
				color:$primary;
			}
		}
	}
}

.palette-alt-1,
.palette-alt-2,
.palette-alt-3 {
	.view-attachments .attachment-link {
		background: white;
	}
}

// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:15px;
		}
		
		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:15px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:15px; 
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}
}

// RESOURCE / PUBLICATION FILES 

main .side-row.view-display-id-files .view-content.view-content-main {
	padding-top:20px;
	.views-row {
    padding: 15px 0 35px;
		&.views-row-last {
			padding-bottom: 0;
		}
	}
}