​.card-tagline-text {
  font:$tagline-font;
  max-width: 80%;
  margin: 0 auto;
  padding: 40px;
  p:last-child {
    margin-bottom: 0;
  }
}
​
// PALETTE SPECIFIC
​
.content-main,
.content-additional {
  .card-tagline-text  {
    background:$tagline-bg-default;
    color:$tagline-color-default;
  }
}
​
.postscript-first .section-row {
  &.palette-default {
    .card-tagline-text  {
      background:$tagline-bg-default;
      color:$tagline-color-default;
    }
  }
  &.palette-alt-1 {
    .card-tagline-text  {
      background:$tagline-bg-alt-1;
      color:$tagline-color-alt-1;  
    }
  }
  &.palette-alt-2 {
    .card-tagline-text  {
      background:$tagline-bg-alt-2;
      color:$tagline-color-alt-2;
    }
  }
  &.palette-alt-3 {
    .card-tagline-text  {
      background:$tagline-bg-alt-3;
      color:$tagline-color-alt-3;
    }
  }
  &.palette-highlight {
    .card-tagline-text  {
      background:$tagline-bg-highlight;
      color:$tagline-color-highlight;
    }
  }
}