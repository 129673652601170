@media (min-width: $wide) {
  .no-sidebars .node-content .card-summary,
  .no-sidebars .node-content .card-intro,
  .no-sidebars .node-content .card-body,
  .no-sidebars .node-content .card-authors {
    padding: 0 16% 0 0;
  }
  .no-sidebars .node-content .detail.full-width .card-authors {
    padding: 0;
  }
}

.node-leaf {
  main {
    background-color: $main-page-bg;
  }

  #content {
    padding: $vert-space;
    background-color: white;
    overflow: hidden;

    .card-title-field h1 {
      font-size: 2rem;
      font-weight: normal;
    }

    .card-intro {
      margin-top: $vert-space;
    }

    .card-image {
      float: left;
      margin: 0 $vert-space $horz-space 0;

      @media(min-width: 640px) {
        max-width: 55%;
      }
    }
  }
}

main section#content {
  .content-main,
  .content-additional {
    padding: 0;
    @include media(880px) {
      padding: 0 20px 0 0;
    }
  }

  .content-main + .content-additional {
    margin-top: $vert-space*1.5;
  }
}

.node-type-page .node-content {
  h1 {
    margin-bottom: 60px;

    .card-title-field {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before,
      &:after {
        display: inline-block;
        height: 6px;
        width: 40px;
        content: "";
        background-color: #C4E8FA;
        margin-bottom: 0; //10px;
      }

      &:before {
        margin-right: 20px;
      }

      &:after {
        margin-left: 20px;
      }
    }
  }
}

.node-content {
  h1 .card-title-field {
    font-size: 2.666rem;
    line-height: 1.1875;
    color: $primary;
    font-family: $title-font-family;
    margin: 0 0 0;
    text-transform: none;
  }

  .card-image {
    margin-bottom: $vert-space;
  }

  .card-display-date,
  .card-date-display-override {
    font-size: 0.875rem;
    margin-right: 3px;
    padding: 0;
  }

  .card-location {
    display: inline-block;
    font-size: 0.8125em;
  }

  .card-date,
  .card-display-date,
  .card-date-display-override,
  .card-location {
    margin-bottom: $vert-space*0.75;
    font-size: 1em;
  }

  .card-job-title {
    color: $secondary;
    font-size: 1.125em;
  }

  .card-summary {
    color: $primary;
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.3;
  }

  &.node-organisation {
    .card-logo {
      text-align: center;
      background-color: transparent;
      margin-bottom: 1rem;

      @media(min-width: $narrow) {
        float: right;
        margin-left: 1.25rem;
        max-width: calc(50% - 0.625rem);
      }
    }

    .card-link {
      margin-top: 30px;
    }
  }
}

.side-row {
  .label {
    font-weight: bold;
  }
}

.node-type-publication,
.node-type-ipcrg-publication, {
  .card-journal,
  .card-citation-date,
  .card-volume,
  .card-pages {
    display: inline;

    div,
    div::after {
      display: inline;
    }
  }

  .card-journal {
    font-weight: 600;

    div::after {
      content: ",";
      margin: 0 2px 0 0;
    }
  }

  .card-citation-date,
  .card-year {
    font-weight: 600;

    div::after {
      content: ";";
    }
  }

  .card-volume {
    div::after {
      content: ":";
      margin: 0 0px 0 2px;
    }
  }

  .card-doi,
  .card-pmid {
    margin-top: 5px;
    clear: left;

    .card-label {
      display: inline-block;
      font-weight: 600;
    }
  }

  .card-authors,
  .card-abbr-authors {
    //font-style:italic;
    margin-bottom: $vert-space/4;
  }

  .card-link {
    margin-bottom: 5px;
  }

  .view-display-id-pub_details.side-row {
    margin-bottom: 0;
  }

  .altmetric-embed {
    padding-top: 0;
    padding-bottom: 0;
  }
}

//IPCRG RESOURCES AND PUBLICATIONS

.card-ipcrg-file-date {
  margin-bottom: 5px;
  color: $primary;
  font-size: 0.777em;
}

.card-ipcrg-file-language {
  color: $primary;
  font-size: 0.777em;
}

.node-type-ipcrg-resource,
.node-type-ipcrg-publication {
  #sidebar-second {
    padding-top: 50px;

  }

  .view-display-id-files {
    .attachment-link {
      margin-bottom: 10px;
    }

    .views-row-last .attachment-link {
      margin-bottom: 10px;
    }
  }
}

.node-details {
  background: white;
  width: 100%;
  padding: 30px;
  margin: 30px 0;

  .label {
    font-size: 1.333em;
    color: $primary;
    margin-bottom: 5px;
  }

  .detail {
    display: inline-block;
    color: $noshime_flower;
  }

  .node-details-wrapper {
    display: flex;
    flex-wrap: wrap;

    .node-details-section {
      margin: 1.5em 0 1em 0;
      order: 1;

      &:first-child {
        margin: 0 0 1em 0;
      }
    }

    .row-3 {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      order: 2;

      .detail {
        margin-bottom: 20px;

        li {
          margin-bottom: 5px !important;
        }
      }
    }

    .row-1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      order: 3;

      .detail {
        flex-basis: 100%;
      }
    }
  }
}

.no-sidebars {
  .node-details .row-3 {
    .detail {
      flex-basis: 100%;
      @media(min-width: 540px) {
        flex-basis: 50%;
      }
      @media(min-width: 720px) {
        flex-basis: 33%;
      }
    }
  }
}

.one-sidebar {
  .node-details .row-3 {
    .detail {
      flex-basis: 100%;
      @media(min-width: 800px) {
        flex-basis: 50%;
      }
      @media(min-width: 1080px) {
        flex-basis: 33%;
      }
      margin-bottom: 20px;
    }
  }
}

.card-abstract {
  font-style: italic;
}

.page-node-76 {
  main {
    padding-bottom: 0;
  }

  .postscript-first .section-row {
    padding-top: 0;
  }
}


.node-type-ipcrg-gallery {
  .card-ipcrg-gallery-image {
    margin-top: $vert-space*1.5;
  }

  .card-ipcrg-gallery-video {
    margin-top: $vert-space*1.5;

    .embedded-video .player {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }

    .embedded-video .player iframe,
    .embedded-video .player object,
    .embedded-video .player embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .gallery-download {
    .button {
      margin: -20px 0 0 -20px;
      width: calc(100% + 40px);
      text-align: center;
      //border-bottom: 3px solid $main-page-bg !important;
      background-color: $vivid_cerulean !important;

      &:hover {
        background-color: $red_radish !important;
      }
    }

    .gallery-side-text {
      color: $noshime_flower;
      font-size: 0.778rem;
      padding-top: 20px;

      .card-extension {
        text-transform: uppercase;
        font-weight: 600;
      }
      .card-extension,
      .card-php,
      .card-filesize {
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
}

