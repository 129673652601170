section .section-row.section-with-promo {
  padding: 0;
  width: 100%;
  position: relative;
  line-height: 1;
  overflow: hidden;
  background-color: transparent !important;
  .section-inner {
    max-width: none;
    width: 100%;
    @media(min-width: 720px) {
      height: 400px;
    }
  }
}
.view-promo {
  @media(min-width: 720px) {
    height: 400px;
    margin: 0 -30px;
  }
  .promo {
    width: 100%;
    .card-image { width: 100%; }
    picture, img {
      max-width: none;
      display: inline-block;
      vertical-align: baseline;
      @media(max-width: 720px) {
        margin-left: calc(50% - 780px);
      }
      @media(min-width: 1600px) {
        width: 100%;
      }
    }
  }
  .card-promo-text {
    position: relative;
    //background: $promo-bg no-repeat 50% 50% / 655px 491px;
    background-color: $sea_blue;
    //top: -20px;
    //height:491px;
    //width:655px;
    //padding:110px 230px 150px 70px;
    height: auto;
    width: 100%;
    padding: 20px 30px;
    display: inline-block;
    top: -55px;
    @media(min-width: 720px) {
      position: absolute;
      top: -20px;
      background: $promo-bg no-repeat 50% 50% / 715px 535px transparent;
      right: 0;
      height:535px;
      width:715px;
      padding:130px 220px 150px 70px;
    }
    @media(min-width: 960px) {
      //
      right: 85px;
    }
    @media(min-width: 1600px) {
      right: calc(50% - 800px + 85px);
    }
    .card-title-field {
      font-size: 42px;
      margin-bottom: 30px;
      display: inline-block;
      @media(min-width: 720px) {
        margin-bottom: 50px;
      }
    }
    .promo-description {
      p {
        font-size: 22px;
      }
    }
  }

  a.card-promo-text {
    color: white!important;
    &:hover {
      //background: $promo-bg-hover no-repeat 50% 50% / 655px 491px;
      background-color: $vivid_cerulean;

      @media(min-width: 720px) {
        background: $promo-bg-hover no-repeat 50% 50% / 715px 535px transparent;
      }
    }
  }
}
