// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		border-radius: 50%;
		height:48px;
		width:48px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 80px;
		&:empty { display: none; }
	button.slick-arrow {
		padding: 25px;
	}
	.slick-dots {
		bottom:30px;
		@media(min-width: 960px){
			bottom:120px;
		}
		// @media(min-width: 1200px){
		// 	bottom:120px;
		// }
		@media(min-width:1600px) {
			bottom: calc(50% - 180px);
		}
	}	
}

.card-slide-content-title {
    display: none;
}


// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}
							// 
							// @include media($wide) {
							// 	left:calc(50% - 800px);
							// 	width: auto;
							// }
						}
					}
					
					.card-slide-description {
						bottom:0;
						position:relative;
						background:$prussian_blue;
						font:$slide-text-font;
						padding:20px 20px 75px;
						margin:0;
						text-align: center;
						color:$slide-text-color;
						min-height:66px;
						@media(min-width:960px) {
							background:$slide-narrow-bg no-repeat 50% 50% / 380px 380px;
							height: 380px;
    					width: 380px;
							top: calc(50% - 190px);
							left:75px;
							display: flex;
					    justify-content: center;
					    align-items: center;
						}
						@media(min-width:1200px) {
							background:$slide-wide-bg no-repeat 50% 50% / 460px 460px;
							height: 460px;
    					width: 460px;
							top: calc(50% - 230px);
						}
						
						p {
							font:$slide-text-font;
							font-size: 1.5rem;
							margin: 0;
							@media(min-width:1200px) {
								font-size: 1.777rem;
							}
						}
						@include media(960px) {
							position:absolute;
							padding: 110px 40px;
						}
						@include media(1200px) {
							position:absolute;
							padding: 160px 40px;
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:0;
		width:100%;
		left: 20px;
		width: calc(100% - 40px);
		@include media(960px) {
		width: 430px;
		left: 50px;
    top: 0;
		}
		@include media(1200px) {
			left: 50px;
			width: 510px;
		}
	}
}

.view-slider.view-id-slider {
	.slick-slider {
		background: $prussian_blue;
	}
}

// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		// @media (min-width:1600px) {
		// 	max-height: 450px;
		// 	overflow: hidden;
		// 
		// 	.view-slider .view-content .slick-list .slick-track .views-row {
		// 		margin: 0;
		// 
		// 		.card-image {
		// 			.card-slide-image {
		// 				position:relative;
		// 				img {
		// 					max-width:none;
		// 					position:relative;
		// 					width: 100%;
		// 
		// 					@include media($narrow) {
		// 						left: -10px;
		// 						width: calc(100% + 20px);
		// 					}
		// 
		// 					@include media($wide) {
		// 						left:calc(50% - 800px);
		// 						width: auto;
		// 					}							
		// 
		// 					@media (min-width:1600px) {
		// 						left:0;
		// 						width: 100%;
		// 					}
		// 				}
		// 			}
		// 		}
		// 
		// 		.card-slide-description {
		// 			bottom:0;
		// 			position:relative;
		// 			background-color:$slide-text-bg-color;
		// 			font:$slide-text-font;
		// 			padding:17px 20px 55px 20px;
		// 			margin:0;
		// 			color:$slide-text-color;
		// 			min-height:66px;
		// 
		// 			@include media($narrow) {
		// 				background-color:$slide-text-bg-color;
		// 				position:absolute;
		// 				padding:17px 20px;
		// 				max-width:75%;
		// 			}
		// 			@include media(1600px) {
		// 				top: calc(450px - 66px);
		// 				bottom:auto;
		// 				left: calc(50% - 800px + 20px);
		// 				max-width:50%;
		// 			}
		// 		}
		// 	}
		// }

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}

		// .view-slider .view-footer {
		// 	@include media(1600px) {
		// 		top: calc(450px - 117px);
		// 		bottom:auto;
		// 		left: calc(50% - 800px + 20px);
		// 		max-width: calc(1600px - 40px);
		// 	}
		// }
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%; 
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}



