.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		color: $vivid_cerulean !important;
		font-weight: 600;
		height:40px;
		width:40px;
		text-indent: -9999px;
		
		&:hover {
			color:$action-hover!important;
		}
	}
	li.pager-next {
		// float: right;
		// position: absolute;
		// right: 55px;
		margin-left: 40px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			background: $arrow-radish-right no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 2px $red_radish;
			&:hover {
				display: block;
				background: $arrow-sea-right no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:solid 2px $sea_blue;
			}
		}
	}
	li.pager-last {
		//float: right;
		margin-left: 5px;
		a {
			display: block;
			background: $arrow-radish-last no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 2px $red_radish;
			&:hover {
				display: block;
				background: $arrow-sea-last no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:solid 2px $sea_blue;
			}
		}
	}
	li.pager-previous {
		// float: left;
		// position: absolute;
		// left: 55px;
		margin-right: 40px;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			background: $arrow-radish-left no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 2px $red_radish;
			&:hover {
				display: block;
				background: $arrow-sea-left no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:solid 2px $sea_blue;
			}
		}
	}
	li.pager-first {
		//float: left;
		margin-right: 5px;
		a {
			display: block;
			background: $arrow-radish-first no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 2px $red_radish;
			&:hover {
				display: block;
				background: $arrow-sea-first no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:solid 2px $sea_blue;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0 5px;
		padding:0;
	}
	li.pager-current {
		color:$sea_blue;
		// padding:0.25em 0.5em;
		// margin-top: 0.25em;
	}
}